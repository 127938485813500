/**
 * @flow
 */

/* eslint-disable */

import type { ConcreteRequest } from 'relay-runtime';
export type LoginFormMutationVariables = {|
  email: string,
  password: string,
|};
export type LoginFormMutationResponse = {|
  +login: ?({|
    +__typename: "LoginSuccess",
    +token: string,
    +user: ?{|
      +id: string,
      +email: ?string,
    |},
  |} | {|
    +__typename: "InputError",
    +message: ?string,
  |} | {|
    +__typename: "SystemError",
    +message: ?string,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |})
|};
export type LoginFormMutation = {|
  variables: LoginFormMutationVariables,
  response: LoginFormMutationResponse,
|};

/*
mutation LoginFormMutation(
  $email: String!
  $password: String!
) {
  login(email: $email, password: $password) {
    __typename
    ... on LoginSuccess {
      token
      user {
        id
        email
      }
    }
    ... on InputError {
      message
    }
    ... on SystemError {
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "LoginSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "InputError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "SystemError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginFormMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "74e1996fd3a206208b64a6dcdf8081d2",
    "id": null,
    "metadata": {},
    "name": "LoginFormMutation",
    "operationKind": "mutation",
    "text": "mutation LoginFormMutation(\n  $email: String!\n  $password: String!\n) {\n  login(email: $email, password: $password) {\n    __typename\n    ... on LoginSuccess {\n      token\n      user {\n        id\n        email\n      }\n    }\n    ... on InputError {\n      message\n    }\n    ... on SystemError {\n      message\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node: any).hash = '44d8fdbacbd78576d29610290f4c24aa';
export default node;
