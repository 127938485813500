// @flow strict

import React, { type Node } from 'react';

type Props = {
  +label: FbtWithoutString,
  +onClick: () => void,
  +tint?: 'main' | 'danger' | 'default',
  +isDisabled?: boolean,
  +inProgress?: boolean,
  +type?: string,
  ...
};

const Spinner = () => (
  <svg
    sxt="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle sxt="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path
      sxt="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export default function Button({
  label,
  onClick,
  isDisabled,
  tint = 'default',
  inProgress = false,
  type = 'button',
  ...props
}: Props): Node {
  return (
    <button
      {...props}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={isDisabled ?? inProgress}
      sxt={`${
        tint === 'main'
          ? 'bg-indigo-600 focus:bg-indigo-700 hover:bg-indigo-700 text-white'
          : 'bg-white border-gray-300 focus:bg-gray-50 hover:bg-gray-50 text-gray-700'
      } border border-transparent font-medium inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    >
      {inProgress && <Spinner />}
      {label}
    </button>
  );
}
