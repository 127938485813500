// @flow strict-local

import React, { type Node } from 'react';
import { fbt } from 'fbt';

import { getPageProps } from '../src/app/getPageProps';
import MainLayout from '../src/components/layout/Main';
import LoginForm from '../src/login/LoginForm';

export default function Login(): Node {
  return (
    <MainLayout header={<fbt desc="Page layout header">Login</fbt>}>
      <div sxt="px-4 py-8 sm:px-0">
        <div sxt="border-4 border-dashed border-gray-200 rounded-lg h-96">
          <LoginForm />
        </div>
      </div>
    </MainLayout>
  );
}

export const getServerSideProps = getPageProps;
