// @flow

import React, { type Node } from 'react';
import { Field, Form, Formik } from 'formik';
import { graphql, useMutation } from '@adeira/relay';
import { useRouter } from 'next/router';
import { fbt } from 'fbt';
import * as Yup from 'yup';

import type { LoginFormMutation } from './__generated__/LoginFormMutation.graphql';
import Input from '../components/Input';
import { useAppMessage } from '../useAppMessage';
import Button from '../components/Button';

const LoginForm = (): Node => {
  const [login, isLoading] = useMutation<LoginFormMutation>(graphql`
    mutation LoginFormMutation($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        __typename
        ... on LoginSuccess {
          token
          user {
            id
            email
          }
        }
        ... on InputError {
          message
        }
        ... on SystemError {
          message
        }
      }
    }
  `);

  const { showError, clear } = useAppMessage();
  const router = useRouter();

  function loginFormSchema() {
    return Yup.object().shape({
      email: Yup.string().required(fbt('Email is required', 'Login form validation message')),
      password: Yup.string().required(fbt('Password is required', 'Login form validation message')),
    });
  }

  const onSubmit = (values) => {
    login({
      variables: values,
      onError: () => {
        showError(fbt('Internal client error', 'Unexpected GraphQL error'));
      },
      onCompleted: ({ login }, errors) => {
        if (errors != null) {
          showError(errors[0].message);
        }

        if (login?.__typename === 'LoginSuccess') {
          clear();
          router.push('/exercises');
        } else {
          showError((login: any)?.message ?? 'Server error');
        }
      },
    });
  };

  return (
    <div sxt="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div sxt="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <Formik
          validationSchema={loginFormSchema()}
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" sxt="block text-sm font-medium leading-5 text-gray-700">
                  <fbt desc="Field in Login form">Email</fbt>
                </label>
                <Field name="email">
                  {({ field }) => <Input id="email" type="email" {...field} />}
                </Field>
              </div>

              <div sxt="mt-6">
                <label htmlFor="password" sxt="block text-sm font-medium leading-5 text-gray-700">
                  <fbt desc="Field in Login form">Password</fbt>
                </label>
                <Field name="password">
                  {({ field }) => <Input id="password" type="password" {...field} />}
                </Field>
              </div>

              <div sxt="mt-6 flex flex-row-reverse">
                <Button
                  type="submit"
                  tint="main"
                  inProgress={isLoading}
                  data-testid="submit-button"
                  onClick={handleSubmit}
                  label={<fbt desc="Action button in Login form">Sign in</fbt>}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
