// @flow strict

import React, { type Node } from 'react';
// $FlowFixMe[untyped-import]
import { ExclamationCircleIcon } from '@heroicons/react/solid';
// $FlowFixMe[untyped-import]
import { useField } from 'formik';

type Props = {
  +name: string,
  +id: string,
  +type: string,
  ...
};

type ErrorIconProps = {
  +hasError: boolean,
};
type ErrorMessageProps = {
  +error: ?string,
};

const ErrorIcon = ({ hasError }: ErrorIconProps) => {
  if (!hasError) {
    return null;
  }
  return (
    <div sxt="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <ExclamationCircleIcon sxt="h-5 w-5 text-red-500" aria-hidden="true" />
    </div>
  );
};

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  if (error == null) {
    return null;
  }
  return (
    <p sxt="mt-2 text-sm text-red-600" id="email-error">
      {error}
    </p>
  );
};

// https://tailwindui.com/components/application-ui/forms/input-groups#component-7a5297f99a5ed22df80939dd1986de5f
export default function Input(props: Props): Node {
  const meta = useField(props)[1];
  const hasError = meta.touched && meta.error != null;
  const error = hasError ? meta.error : null;

  return (
    <div>
      <div sxt="mt-1 relative rounded-md shadow-sm">
        <input
          {...props}
          sxt={
            hasError
              ? 'block w-full pl-3 py-2 pr-10 border border-red-300 text-red-900 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              : 'block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
          }
        />
        <ErrorIcon hasError={hasError} />
      </div>
      <ErrorMessage error={error} />
    </div>
  );
}
